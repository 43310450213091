content {
    margin: 75px 2vw 0 2vw;
    display: block;
}

th, td {
    text-align: center;
}

#delete-timeslips-dropdown {
    margin-bottom: 15px;
}
